import React, { useState } from "react";
import {
  Card,
  CardHeader,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { formatDate } from "../../Functions/formatDate";
import { useNavigate } from "react-router-dom";
import convertMvToPercent from "../../Functions/convertMvToPercent";
import moment from "moment";
import formatTemperature from "../../Functions/formatTemperature";
import DeviceCardPanel from "../deviceCardPanel";
import convertPercentToColour from "../../Functions/convertPercentToColour";
import DeviceTypeAvatar from "../DeviceTypeAvatar";
import { useSelector } from "react-redux";
import { BatteryFull } from "@mui/icons-material";

export default function DeviceCard(props) {
  const { device } = props;
  const [deviceFrames] = useState(
    //json decode from device.last_data
    device.last_data ? JSON.parse(device.last_data) : null
  );

  const isMobile = useSelector((state) => state.isMobile);

  const theme = useTheme();

  const navigate = useNavigate();

  let voltageMv =
    deviceFrames && deviceFrames.voltageMv
      ? deviceFrames.voltageMv
      : deviceFrames && deviceFrames.batteryMv
      ? deviceFrames.batteryMv
      : deviceFrames && deviceFrames.bat
      ? deviceFrames.bat
      : 0;

  let deviceStatus =
    deviceFrames && deviceFrames.battery_status
      ? deviceFrames.battery_status
      : "N/A";

  return (
    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={4} sx={{ mb: 1 }}>
      <>
        <Card
          sx={{
            cursor: "pointer",
            pl: 1,
            pr: 1,
            height: "100%",
            //rounded corners
            borderRadius: 2,
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
          }}
          onClick={
            () => navigate(`/device/${device.id}`)
            //onhover set shadow
          }
          className="device-card"
        >
          <CardHeader
            avatar={<DeviceTypeAvatar device={device} />}
            action={
              //if devicettype is 18, set hasBattery to false
              device &&
              device.device_type &&
              device.device_type !== 19 &&
              device.device_type !== 27 &&
              device.device_type !== 23 &&
              device.device_type !== 28 &&
              device.device_type !== 33 &&
              device.device_type !== 39 &&
              device.device_type !== 43 &&
              device.device_type !== 44 &&
              device.device_type !== 45 &&
              device.device_type !== 48 ? (
                <Tooltip
                  title={
                    voltageMv
                      ? "Battery: " +
                        convertMvToPercent(voltageMv, device.device_brand)
                      : "Battery: N/A"
                  }
                  position="top"
                  arrow
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: convertPercentToColour(
                          convertMvToPercent(voltageMv, device.device_brand)
                        ),

                        fontSize: "1.5rem",
                      }}
                      variant="p"
                    >
                      {voltageMv
                        ? convertMvToPercent(voltageMv, device.device_brand)
                        : "N/A"}
                    </Typography>
                    <BatteryFull
                      sx={{
                        color: convertPercentToColour(
                          convertMvToPercent(voltageMv, device.device_brand)
                        ),
                        fontSize: "1.5rem",
                        mr: -1.5,
                      }}
                    />
                  </div>
                </Tooltip>
              ) : device.device_type === 28 ? (
                <Tooltip
                  title={
                    deviceStatus ? "Battery: " + deviceStatus : "Battery: N/A"
                  }
                  position="top"
                  arrow
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: deviceStatus === "OK" ? "green" : "red",
                        fontSize: "1.5rem",
                      }}
                      variant="p"
                    >
                      {deviceStatus === "OK"
                        ? "OK"
                        : deviceStatus === "LOW_BATTERY"
                        ? "Low Battery"
                        : "N/A"}
                    </Typography>
                    <BatteryFull
                      sx={{
                        color: deviceStatus === "OK" ? "green" : "red",
                        fontSize: "1.5rem",
                        mr: -1.5,
                      }}
                    />
                  </div>
                </Tooltip>
              ) : null
            }
            title={device.name}
            titleTypographyProps={
              //bigger font
              {
                fontSize: "1rem",
              }
            }
            subheader={
              device.time_updated
                ? formatDate(device.time_updated)
                : "No Data Yet..."
            }
            subheaderTypographyProps={{
              color: "textSecondary",
            }}
            sx={{ p: 1, pb: 2 }}
          />
          <Grid container spacing={1}>
            {device.device_type === 1 && (
              // GPS Tracker
              <>
                <Grid item xs={12} md={12}>
                  <DeviceCardPanel
                    title="Location"
                    content={
                      deviceFrames && deviceFrames.geocode
                        ? deviceFrames.geocode
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 2 && (
              // Air Quality
              <>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Temperature"
                    content={
                      deviceFrames && deviceFrames.temperature
                        ? formatTemperature(deviceFrames.temperature)
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Humidity"
                    content={
                      deviceFrames && deviceFrames.humidity
                        ? deviceFrames.humidity + "%"
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="CO2"
                    content={
                      deviceFrames && deviceFrames.co2
                        ? deviceFrames.co2 + "ppm"
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 3 && (
              // Temperature, Humidity
              <>
                <Grid item xs={6} md={6}>
                  <DeviceCardPanel
                    title="Temperature"
                    content={
                      deviceFrames && deviceFrames.temperature
                        ? formatTemperature(deviceFrames.temperature)
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <DeviceCardPanel
                    title="Humidity"
                    content={
                      deviceFrames && deviceFrames.humidity
                        ? deviceFrames.humidity + "%"
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 4 && (
              // Tracker with button
              <>
                <Grid item xs={12} md={10}>
                  <DeviceCardPanel
                    title="Location"
                    content={
                      deviceFrames && deviceFrames.geocode
                        ? deviceFrames.geocode
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <DeviceCardPanel
                    title="Alarm"
                    content={
                      deviceFrames &&
                      deviceFrames.sos_event &&
                      deviceFrames.sos_event === 1
                        ? "Yes"
                        : "No"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 5 && (
              //distance sensor
              <>
                <Grid item xs={12} md={12}>
                  <DeviceCardPanel
                    title="Distance"
                    content={
                      deviceFrames && deviceFrames.distance
                        ? deviceFrames.distance + "mm"
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 6 && (
              //motion sensor
              <>
                <Grid item xs={12} md={6}>
                  <DeviceCardPanel
                    title="Motion"
                    content={deviceFrames && deviceFrames.motion ? "Yes" : "No"}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DeviceCardPanel
                    title="Time"
                    content={
                      deviceFrames && deviceFrames.motion
                        ? formatDate(
                            moment(device.time_updated)
                              .subtract(deviceFrames.time, "seconds") //subtract time from time_updated
                              .format()
                          )
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 7 && (
              <>
                <Grid item xs={12} md={3}>
                  <DeviceCardPanel
                    title="Temperature"
                    content={
                      deviceFrames && deviceFrames.temperature
                        ? formatTemperature(deviceFrames.temperature)
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={9}>
                  <DeviceCardPanel
                    title="Location"
                    content={
                      deviceFrames && deviceFrames.geocode
                        ? deviceFrames.geocode
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 8 && (
              //paxcounter
              <>
                {
                  //if ble and wifi, md=3, else if not ble and wifi, md=6, else if ble and not wifi, md=6
                }
                <Grid
                  item
                  xs={12}
                  md={
                    //if deviceFrames contains wifi and ble, md=3 else if deviceFrames contains wifi and not ble, md=6 else if deviceFrames contains ble and not wifi, md=6 if none of the above, md=10
                    deviceFrames && deviceFrames.wifi && deviceFrames.ble
                      ? 4
                      : deviceFrames && deviceFrames.wifi && !deviceFrames.ble
                      ? 8
                      : deviceFrames && deviceFrames.ble && !deviceFrames.wifi
                      ? 8
                      : 12
                  }
                >
                  <DeviceCardPanel
                    title="Total"
                    content={
                      deviceFrames
                        ? deviceFrames.count
                          ? deviceFrames.count
                          : "N/A"
                        : "N/A"
                    }
                  />
                </Grid>
                {deviceFrames && deviceFrames.ble ? (
                  <Grid item xs={12} md={4}>
                    <DeviceCardPanel
                      title="BLE"
                      content={
                        deviceFrames && deviceFrames.ble
                          ? deviceFrames.ble
                          : "N/A"
                      }
                    />
                  </Grid>
                ) : null}
                {deviceFrames && deviceFrames.wifi ? (
                  <Grid item xs={12} md={4}>
                    <DeviceCardPanel
                      title="WiFi"
                      content={
                        deviceFrames && deviceFrames.wifi
                          ? deviceFrames.wifi
                          : "N/A"
                      }
                    />
                  </Grid>
                ) : null}
              </>
            )}
            {device.device_type === 9 && (
              //temperature 1 and 2
              <>
                <Grid item xs={12} md={6}>
                  <DeviceCardPanel
                    title="Temperature 1"
                    content={
                      deviceFrames && deviceFrames.temperature_1
                        ? deviceFrames.temperature_1 + "°C"
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DeviceCardPanel
                    title="Temperature 2"
                    content={
                      deviceFrames && deviceFrames.temperature_2
                        ? deviceFrames.temperature_2 + "°C"
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 10 && (
              //motion sensor
              <>
                <Grid item xs={12} md={6}>
                  <DeviceCardPanel
                    title="Motion"
                    content={deviceFrames && deviceFrames.motion ? "Yes" : "No"}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DeviceCardPanel
                    title="Time"
                    content={
                      deviceFrames && deviceFrames.motion
                        ? formatDate(
                            moment(device.time_updated)
                              .subtract(deviceFrames.time, "seconds")
                              .format()
                          )
                        : "N/A"
                    }
                  />
                </Grid>
                {
                  //occupancy count
                }
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    mb: isMobile ? 2 : 0,
                  }}
                >
                  <Tooltip
                    sx={{ height: "100%" }}
                    title={
                      deviceFrames &&
                      deviceFrames.count &&
                      deviceFrames.count > 0
                        ? "Occupancy Count: " + deviceFrames.count
                        : "Occupancy Count: 0"
                    }
                    position="top"
                    arrow
                  >
                    <Card
                      sx={{
                        backgroundColor:
                          theme.palette.mode === "dark" ? "#1f1f1f" : "#f0f0f0",
                        p: 2,
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Count
                      </Typography>
                      <Typography variant="p" sx={{ fontSize: "1rem" }}>
                        {deviceFrames &&
                        deviceFrames.count &&
                        deviceFrames.count > 0
                          ? deviceFrames.count
                          : "0"}
                      </Typography>
                    </Card>
                  </Tooltip>
                </Grid>
              </>
            )}
            {device.device_type === 11 && (
              //Door/Window Sensor
              <>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Open?"
                    content={
                      deviceFrames &&
                      deviceFrames.door_open &&
                      deviceFrames.door_open === 1
                        ? "Yes"
                        : "No"
                    }
                  />
                </Grid>

                {
                  //open count
                }
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    mb: isMobile ? 2 : 0,
                  }}
                >
                  <DeviceCardPanel
                    title="Count"
                    content={
                      deviceFrames &&
                      deviceFrames.count &&
                      deviceFrames.count > 0
                        ? deviceFrames.count
                        : "0"
                    }
                  />
                </Grid>
                {
                  //temperature
                }
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    mb: isMobile ? 2 : 0,
                  }}
                >
                  <DeviceCardPanel
                    title="Temperature"
                    content={
                      deviceFrames && deviceFrames.temperature
                        ? formatTemperature(deviceFrames.temperature)
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 12 && (
              //Water Leak Sensor
              <>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Leak?"
                    content={
                      deviceFrames &&
                      deviceFrames.water_leak &&
                      deviceFrames.water_leak === 1
                        ? "Yes"
                        : "No"
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    mb: isMobile ? 1 : 0,
                  }}
                >
                  <DeviceCardPanel
                    title="Temperature"
                    content={
                      deviceFrames && deviceFrames.temperature
                        ? formatTemperature(deviceFrames.temperature)
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    mb: isMobile ? 2 : 0,
                  }}
                >
                  <DeviceCardPanel
                    title="Humidity"
                    content={
                      deviceFrames && deviceFrames.humidity
                        ? deviceFrames.humidity + "%"
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 13 && (
              //Air Quality Sensor with tvoc, co2, temperature, humidity
              <>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    mb: isMobile ? 2 : 0,
                  }}
                >
                  <DeviceCardPanel
                    title="TVOC"
                    content={
                      deviceFrames && deviceFrames.tvoc
                        ? deviceFrames.tvoc + "ppb"
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="CO2"
                    content={
                      deviceFrames && deviceFrames.co2
                        ? deviceFrames.co2 + "ppm"
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <DeviceCardPanel
                    title="Temp"
                    content={
                      deviceFrames && deviceFrames.temperature
                        ? formatTemperature(deviceFrames.temperature)
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <DeviceCardPanel
                    title="Humidity"
                    content={
                      deviceFrames && deviceFrames.humidity
                        ? deviceFrames.humidity + "%"
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 14 && (
              //pet tracker,show location and isLost (true/false)
              <>
                <Grid item xs={12} md={3}>
                  <DeviceCardPanel
                    title="Lost?"
                    content={
                      deviceFrames && deviceFrames.isLost
                        ? "Yes"
                        : deviceFrames && deviceFrames.isLost === false
                        ? "No"
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={9}>
                  <DeviceCardPanel
                    title="Location"
                    content={
                      deviceFrames && deviceFrames.geocode
                        ? deviceFrames.geocode
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 15 && (
              // Oyster3
              <>
                <Grid item xs={12} md={12}>
                  <DeviceCardPanel
                    title="Location"
                    content={
                      deviceFrames && deviceFrames.geocode
                        ? deviceFrames.geocode
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 16 && (
              // Oyster3
              <>
                <Grid item xs={12} md={12}>
                  <DeviceCardPanel
                    title="Location"
                    content={
                      deviceFrames && deviceFrames.geocode
                        ? deviceFrames.geocode
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 17 && (
              // Cayenne LLP
              <>
                <Grid item xs={12}>
                  <DeviceCardPanel
                    title="Cayenne LLP"
                    content={
                      deviceFrames ? JSON.stringify(deviceFrames) : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 18 && (
              //tektelic vivid
              <>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Temperature"
                    content={
                      deviceFrames && deviceFrames.temperature
                        ? formatTemperature(deviceFrames.temperature)
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Humidity"
                    content={
                      deviceFrames && deviceFrames.humidity
                        ? deviceFrames.humidity + "%"
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="C02"
                    content={
                      deviceFrames && deviceFrames.co2
                        ? deviceFrames.co2 + "ppm"
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 19 && (
              /*sensecore Kraken*/
              <>
                <Grid item xs={12} md={3}>
                  <DeviceCardPanel
                    title="Has Flow?"
                    content={
                      //check deviceFrames has a HasFlowRate field, if it doesnt return "N/A", else if it does, check if it is true or false, if true return "Yes", if false return "No"
                      deviceFrames && deviceFrames.HasFlowRate !== undefined
                        ? deviceFrames.HasFlowRate
                          ? "Yes"
                          : "No"
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <DeviceCardPanel
                    title="Current Flow"
                    content={
                      deviceFrames &&
                      deviceFrames.instantaneousFlow !== undefined
                        ? deviceFrames.instantaneousFlow
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <DeviceCardPanel
                    title="Current Velocity"
                    content={
                      deviceFrames &&
                      deviceFrames.instantaneousVelocity !== undefined
                        ? deviceFrames.instantaneousVelocity
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <DeviceCardPanel
                    title="Total Usage"
                    content={
                      deviceFrames && deviceFrames.positiveCumulative
                        ? deviceFrames.positiveCumulative
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 20 && (
              /*distance sensor 2*/
              <>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Distance"
                    content={
                      deviceFrames && deviceFrames.distance !== undefined
                        ? deviceFrames.distance + "mm"
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <DeviceCardPanel
                    title="Rssi"
                    content={
                      deviceFrames && deviceFrames.rssi !== undefined
                        ? deviceFrames.rssi
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <DeviceCardPanel
                    title="Hotspot Name"
                    content={
                      deviceFrames && deviceFrames.hotspot_name !== undefined
                        ? deviceFrames.hotspot_name
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 21 && (
              /*power meter*/
              <>
                {
                  //current, voltage, watts
                }
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Current"
                    content={
                      deviceFrames && deviceFrames.current
                        ? deviceFrames.current + "A"
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Voltage"
                    content={
                      deviceFrames && deviceFrames.voltage
                        ? deviceFrames.voltage + "V"
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Watts"
                    content={
                      deviceFrames && deviceFrames.watt
                        ? deviceFrames.watt + "W"
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 22 && (
              <Grid item xs={12} md={12}>
                <DeviceCardPanel
                  title="Leak?"
                  content={
                    deviceFrames && deviceFrames.leak && deviceFrames.leak === 1
                      ? "Yes"
                      : "No"
                  }
                />
              </Grid>
            )}
            {device.device_type === 23 && (
              <>
                <Grid item xs={12} md={2}>
                  <DeviceCardPanel
                    title="Temperature"
                    content={
                      deviceFrames && deviceFrames.temp
                        ? formatTemperature(deviceFrames.temp)
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <DeviceCardPanel
                    title="Humidity"
                    content={
                      deviceFrames && deviceFrames.RH
                        ? deviceFrames.RH + "%"
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <DeviceCardPanel
                    title="CO2"
                    content={
                      deviceFrames &&
                      deviceFrames.CO2 &&
                      deviceFrames.CO2.length
                        ? deviceFrames.CO2[0] + "ppm"
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <DeviceCardPanel
                    title="TVOC"
                    content={
                      deviceFrames &&
                      deviceFrames.TVOC &&
                      deviceFrames.TVOC.length
                        ? deviceFrames.TVOC[0]
                        : "N/A"
                    }
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  <DeviceCardPanel
                    title="PM2.5"
                    content={
                      deviceFrames &&
                      deviceFrames.PM2_5 &&
                      deviceFrames.PM2_5.length
                        ? deviceFrames.PM2_5[0] + "µg/m³"
                        : "N/A"
                    }
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  <DeviceCardPanel
                    title="AQI"
                    content={
                      deviceFrames &&
                      deviceFrames.AQI &&
                      deviceFrames.AQI.length
                        ? deviceFrames.AQI[0]
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 24 && (
              <>
                <Grid item xs={12} md={6}>
                  <DeviceCardPanel
                    title="Temperature"
                    content={
                      deviceFrames && deviceFrames.temp
                        ? formatTemperature(deviceFrames.temp)
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DeviceCardPanel
                    title="Humidity"
                    content={
                      deviceFrames && deviceFrames.RH
                        ? deviceFrames.RH + "%"
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}

            {device.device_type === 25 && (
              <>
                <Grid item xs={12} md={12}>
                  <DeviceCardPanel
                    title="Contact"
                    content={
                      deviceFrames && deviceFrames.state !== undefined
                        ? deviceFrames.state === 1
                          ? "Yes"
                          : "No"
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 26 && (
              <>
                <Grid item xs={12} md={6}>
                  <DeviceCardPanel
                    title="Motion"
                    content={
                      deviceFrames && deviceFrames.state !== undefined
                        ? deviceFrames.state === 1
                          ? "Yes"
                          : "No"
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DeviceCardPanel
                    title="Distance"
                    content={
                      deviceFrames && deviceFrames.distance !== undefined
                        ? deviceFrames.distance + "mm"
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 27 && (
              <>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Min"
                    content={
                      deviceFrames && deviceFrames.min !== undefined
                        ? deviceFrames.min
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Max"
                    content={
                      deviceFrames && deviceFrames.max !== undefined
                        ? deviceFrames.max
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Avg"
                    content={
                      deviceFrames && deviceFrames.avg !== undefined
                        ? deviceFrames.avg
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 28 && (
              <>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Ambient Temperature"
                    content={
                      deviceFrames && deviceFrames.ambient_temp !== undefined
                        ? formatTemperature(deviceFrames.ambient_temp)
                        : "N/A"
                    }
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Leak?"
                    content={
                      deviceFrames && deviceFrames.leak_status
                        ? deviceFrames.leak_status === "NoLeak"
                          ? "No Leak"
                          : deviceFrames.leak_status
                        : "N/A"
                    }
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Total Volume"
                    content={
                      deviceFrames && deviceFrames.total_volume
                        ? deviceFrames.total_volume
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 29 && (
              //rak 4 button
              <>
                <Grid item xs={12} md={3}>
                  <DeviceCardPanel
                    title="Button 1"
                    content={
                      deviceFrames && deviceFrames.B1
                        ? deviceFrames.B1 === 1
                          ? "Pressed"
                          : "Not Pressed"
                        : "N/A"
                    }
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <DeviceCardPanel
                    title="Button 2"
                    content={
                      deviceFrames && deviceFrames.B2
                        ? deviceFrames.B2 === 1
                          ? "Pressed"
                          : "Not Pressed"
                        : "N/A"
                    }
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <DeviceCardPanel
                    title="Button 3"
                    content={
                      deviceFrames && deviceFrames.B3
                        ? deviceFrames.B3 === 1
                          ? "Pressed"
                          : "Not Pressed"
                        : "N/A"
                    }
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <DeviceCardPanel
                    title="Button 4"
                    content={
                      deviceFrames && deviceFrames.B4
                        ? deviceFrames.B4 === 1
                          ? "Pressed"
                          : "Not Pressed"
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 30 && (
              <>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Temp Red"
                    content={
                      deviceFrames && deviceFrames.temp_red
                        ? formatTemperature(deviceFrames.temp_red)
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Temp Black"
                    content={
                      deviceFrames && deviceFrames.temp_black
                        ? formatTemperature(deviceFrames.temp_black)
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Temp White"
                    content={
                      deviceFrames && deviceFrames.temp_white
                        ? formatTemperature(deviceFrames.temp_white)
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 31 && (
              //temperature and humidity
              <>
                <Grid item xs={12} md={6}>
                  <DeviceCardPanel
                    title="Temperature"
                    content={
                      deviceFrames && deviceFrames.temp
                        ? formatTemperature(deviceFrames.temp)
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DeviceCardPanel
                    title="Humidity"
                    content={
                      deviceFrames && deviceFrames.humidity
                        ? deviceFrames.humidity + "%"
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 32 && (
              // DOOR_OPEN_STATUS=door_open_status,
              //DOOR_OPEN_TIMES=open_times,
              //LAST_DOOR_OPEN_DURATION=open_duration,
              <>
                <Grid item xs={12} md={6}>
                  <DeviceCardPanel
                    title="Open?"
                    content={
                      deviceFrames &&
                      deviceFrames.door_open &&
                      deviceFrames.door_open === 1
                        ? "Yes"
                        : "No"
                    }
                  />
                </Grid>

                {
                  //open count
                }
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    mb: isMobile ? 2 : 0,
                  }}
                >
                  <DeviceCardPanel
                    title="Count"
                    content={
                      deviceFrames &&
                      deviceFrames.count &&
                      deviceFrames.count > 0
                        ? deviceFrames.count
                        : "0"
                    }
                  />
                </Grid>
              </>
            )}

            {device.device_type === 33 && (
              //iaq, temperature, humidity , pm1, 2.5, pm 10

              <>
                <Grid item xs={12} md={2}>
                  <DeviceCardPanel
                    title="Temperature"
                    content={
                      deviceFrames && deviceFrames.temperature
                        ? formatTemperature(deviceFrames.temperature)
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <DeviceCardPanel
                    title="Humidity"
                    content={
                      deviceFrames && deviceFrames.humidity
                        ? deviceFrames.humidity + "%"
                        : "N/A"
                    }
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  <DeviceCardPanel
                    title="CO2"
                    content={
                      deviceFrames && deviceFrames.co2
                        ? deviceFrames.co2 + "ppm"
                        : "N/A"
                    }
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  <DeviceCardPanel
                    title="PM1"
                    content={
                      deviceFrames && deviceFrames.PM1_0
                        ? deviceFrames.PM1_0 + "µg/m³"
                        : "N/A"
                    }
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  <DeviceCardPanel
                    title="PM2.5"
                    content={
                      deviceFrames && deviceFrames.PM2_5
                        ? deviceFrames.PM2_5 + "µg/m³"
                        : "N/A"
                    }
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  <DeviceCardPanel
                    title="PM10"
                    content={
                      deviceFrames && deviceFrames.PM10
                        ? deviceFrames.PM10 + "µg/m³"
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}

            {device.device_type === 34 && (
              //temperature air, temperature soil, soil moisture
              <>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Soil Temperature"
                    content={
                      deviceFrames && deviceFrames.soil_temperature
                        ? formatTemperature(deviceFrames.soil_temperature)
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Soil Moisture"
                    content={
                      deviceFrames && deviceFrames.soil_moisture
                        ? deviceFrames.soil_moisture + "%"
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item sx={{ height: "100%" }} xs={4} md={4}>
                  <DeviceCardPanel
                    isMobile={isMobile}
                    isWidget={true}
                    title="Soil Conductivity"
                    content={
                      deviceFrames && deviceFrames.soil_conductivity
                        ? deviceFrames.soil_conductivity
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}

            {device.device_type === 35 && (
              //dragino lsph01 soil temperature, soil ph

              <>
                <Grid item xs={12} md={6}>
                  <DeviceCardPanel
                    title="Soil PH"
                    content={
                      deviceFrames && deviceFrames.soil_ph
                        ? deviceFrames.soil_ph
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DeviceCardPanel
                    title="Soil Temperature"
                    content={
                      deviceFrames && deviceFrames.soil_temperature
                        ? formatTemperature(deviceFrames.soil_temperature)
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}

            {device.device_type === 36 && (
              //dragino llms01 leaft moisture, leaf temperature
              <>
                <Grid item xs={12} md={6}>
                  <DeviceCardPanel
                    title="Leaf Moisture"
                    content={
                      deviceFrames && deviceFrames.leaf_moisture
                        ? deviceFrames.leaf_moisture + "%"
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DeviceCardPanel
                    title="Leaf Temperature"
                    content={
                      deviceFrames && deviceFrames.leaf_temperature
                        ? formatTemperature(deviceFrames.leaf_temperature)
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 37 && (
              //DraginoLSN50V2_d20
              /*
        "TempC1": 30.3,
        "Hum_SHT": 6553.5,
              */
              <Grid item xs={12}>
                <DeviceCardPanel
                  title="Temperature"
                  content={
                    deviceFrames && deviceFrames.temperature
                      ? formatTemperature(deviceFrames.temperature)
                      : "N/A"
                  }
                />
              </Grid>
            )}
            {device.device_type === 38 && (
              //temperature humidity
              <>
                <Grid item xs={12} md={6}>
                  <DeviceCardPanel
                    title="Temperature"
                    content={
                      deviceFrames && deviceFrames.temperature
                        ? formatTemperature(deviceFrames.temperature)
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DeviceCardPanel
                    title="Humidity"
                    content={
                      deviceFrames && deviceFrames.humidity
                        ? deviceFrames.humidity + "%"
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {
              //device type 39
            }
            {device.device_type === 39 && (
              /*amp_hour_accumulation
    uaverage_amps
    maximum_amps
    minimum_amps
    capacitor_voltage
    temperature_celsius*/

              <>
                <Grid item xs={12} md={2}>
                  <DeviceCardPanel
                    title="Amp Hours"
                    content={
                      deviceFrames && deviceFrames.amp_hour_accumulation
                        ? deviceFrames.amp_hour_accumulation
                        : "N/A"
                    }
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  <DeviceCardPanel
                    title="Avg Amps"
                    content={
                      deviceFrames && deviceFrames.uaverage_amps
                        ? deviceFrames.uaverage_amps
                        : "N/A"
                    }
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  <DeviceCardPanel
                    title="Max Amps"
                    content={
                      deviceFrames && deviceFrames.maximum_amps
                        ? deviceFrames.maximum_amps
                        : "N/A"
                    }
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  <DeviceCardPanel
                    title="Min Amps"
                    content={
                      deviceFrames && deviceFrames.minimum_amps
                        ? deviceFrames.minimum_amps
                        : "N/A"
                    }
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  <DeviceCardPanel
                    title="Cap Voltage"
                    content={
                      deviceFrames && deviceFrames.capacitor_voltage
                        ? deviceFrames.capacitor_voltage
                        : "N/A"
                    }
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  <DeviceCardPanel
                    title="Temp"
                    content={
                      deviceFrames && deviceFrames.temperature_celsius
                        ? formatTemperature(deviceFrames.temperature_celsius)
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 40 && (
              <>
                <Grid item xs={6} md={6}>
                  <DeviceCardPanel
                    title="Gas Pressure"
                    content={
                      deviceFrames && deviceFrames.gas_pressure_psi
                        ? deviceFrames.gas_pressure_psi + "psi"
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <DeviceCardPanel
                    title="Transducer mA"
                    content={
                      deviceFrames && deviceFrames.idc_intput_ma
                        ? deviceFrames.idc_intput_ma + "mA"
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 41 && (
              <>
                <Grid item xs={12} md={8}>
                  <DeviceCardPanel
                    title="Location"
                    content={
                      deviceFrames && deviceFrames.geocode
                        ? deviceFrames.geocode
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Ignition"
                    content={
                      deviceFrames && deviceFrames.ignition ? "On" : "Off"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 42 && (
              //2 doors lds03a
              <>
                <Grid item xs={12} md={6}>
                  <DeviceCardPanel
                    title="Door 1"
                    content={
                      deviceFrames && deviceFrames.door_open !== undefined
                        ? deviceFrames.door_open === 1
                          ? "Open"
                          : "Closed"
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DeviceCardPanel
                    title="Door 2"
                    content={
                      deviceFrames && deviceFrames.door_open2 !== undefined
                        ? deviceFrames.door_open2 === 1
                          ? "Open"
                          : "Closed"
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 43 && (
              //plug_state 1 or 0, show on or off
              //instantaneous_active_power in watts
              //instantaneous_power_factor in percentage

              <>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Plug State"
                    content={
                      deviceFrames && deviceFrames.plug_state !== undefined
                        ? deviceFrames.plug_state
                          ? "On"
                          : "Off"
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Power"
                    content={
                      deviceFrames && deviceFrames.instantaneous_active_power
                        ? deviceFrames.instantaneous_active_power
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Power Factor"
                    content={
                      deviceFrames && deviceFrames.instantaneous_power_factor
                        ? deviceFrames.instantaneous_power_factor
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 44 && (
              // bolt2
              <>
                <Grid item xs={12} md={12}>
                  <DeviceCardPanel
                    title="Location"
                    content={
                      deviceFrames && deviceFrames.geocode
                        ? deviceFrames.geocode
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 45 && (
              //iaq
              <>
                <Grid item xs={12} md={3}>
                  <DeviceCardPanel
                    title="Temperature"
                    content={
                      deviceFrames && deviceFrames.temp
                        ? formatTemperature(deviceFrames.temp)
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <DeviceCardPanel
                    title="Humidity"
                    content={
                      deviceFrames && deviceFrames.RH
                        ? deviceFrames.RH + "%"
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <DeviceCardPanel
                    title="CO2"
                    content={
                      deviceFrames &&
                      deviceFrames.CO2 &&
                      deviceFrames.CO2.length
                        ? deviceFrames.CO2[0] + "ppm"
                        : "N/A"
                    }
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <DeviceCardPanel
                    title="AQI"
                    content={
                      deviceFrames &&
                      deviceFrames.AQI &&
                      deviceFrames.AQI.length
                        ? deviceFrames.AQI[0]
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 46 && (
              //door and window, door_open, count and time (last open duration)
              <>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Door Open"
                    content={
                      deviceFrames && deviceFrames.door_open ? "Yes" : "No"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Count"
                    content={
                      deviceFrames && deviceFrames.count
                        ? deviceFrames.count
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Time"
                    content={
                      deviceFrames && deviceFrames.time !== undefined
                        ? deviceFrames.time + "s"
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {
              //device type 47
              device.device_type === 47 && (
                //door_open, count temperature
                <>
                  <Grid item xs={12} md={4}>
                    <DeviceCardPanel
                      title="Door Open"
                      content={
                        deviceFrames && deviceFrames.door_open ? "Yes" : "No"
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <DeviceCardPanel
                      title="Count"
                      content={
                        deviceFrames && deviceFrames.count
                          ? deviceFrames.count
                          : "N/A"
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <DeviceCardPanel
                      title="Temperature"
                      content={
                        deviceFrames && deviceFrames.temperature
                          ? formatTemperature(deviceFrames.temperature)
                          : "N/A"
                      }
                    />
                  </Grid>
                </>
              )
            }

            {
              //energy, current, watts, power factor
              device.device_type === 48 && (
                <>
                  <Grid item xs={12} md={3}>
                    <DeviceCardPanel
                      title="Energy"
                      content={
                        deviceFrames && deviceFrames.energy
                          ? deviceFrames.energy + "kWh"
                          : "N/A"
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <DeviceCardPanel
                      title="Current"
                      content={
                        deviceFrames && deviceFrames.current
                          ? deviceFrames.current + "A"
                          : "N/A"
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <DeviceCardPanel
                      title="Watts"
                      content={
                        deviceFrames && deviceFrames.watts
                          ? deviceFrames.watts + "W"
                          : "N/A"
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <DeviceCardPanel
                      title="Power Factor"
                      content={
                        deviceFrames && deviceFrames.pf
                          ? deviceFrames.pf
                          : "N/A"
                      }
                    />
                  </Grid>
                </>
              )
            }
          </Grid>
        </Card>
      </>
    </Grid>
  );
}
