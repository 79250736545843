import { CONFIG } from "../config";

export default function convertMvToPercent(
  mv = null,
  deviceBrand = null,
  addPercent = true
) {
  if (mv === null) {
    return 0;
  }

  //check isnumeric
  if (isNaN(mv)) {
    //might be a string like "normal", if it is return that string
    return mv;
  }

  //check if deviceBrand is full object for device or just the deviceBrand object
  if (deviceBrand && deviceBrand.deviceBrand) {
    deviceBrand = deviceBrand.deviceBrand;
  }

  // if mv is 2 digits, it is already a percent, so return it
  if (mv.toString().length <= 3) {
    if (!addPercent) {
      return parseInt(mv);
    }

    return mv + "%";
  }

  let minMv = CONFIG.battery_minMV;
  let maxMv = CONFIG.battery_maxMV;

  if (deviceBrand && deviceBrand.batteryMinMV && deviceBrand.batteryMaxMV) {
    minMv = deviceBrand.batteryMinMV;
    maxMv = deviceBrand.batteryMaxMV;
  }

  if (mv > maxMv) {
    mv = maxMv;
  }

  if (mv < minMv) {
    mv = minMv;
  }

  let bat = Math.round(((mv - minMv) / (maxMv - minMv)) * 100, 0);

  //return bat as a string with %
  if (!addPercent) {
    return parseInt(bat);
  }

  return bat + "%";
}
