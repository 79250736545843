import {
  Box,
  Card,
  CardHeader,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { useSelector } from "react-redux";

library.add(fas);

export default function DeviceMetric(props) {
  const {
    title,
    value,
    unit,
    icon,
    iconSize,
    formatter = null,
    trimValue = false,
    valueToTrim = "",
    textsize,
  } = props;
  const theme = useTheme();

  const deviceHistory = useSelector((state) => state.deviceHistory);

  let valueFound = "N/A";

  if (deviceHistory && deviceHistory.length > 0) {
    let dataObject = deviceHistory[deviceHistory.length - 1];
    let jsonData = dataObject.data;

    try {
      jsonData = JSON.parse(jsonData);

      valueFound = jsonData[value];

      //if the valueFound is an array, then only reutrn the first value
      if (Array.isArray(valueFound)) {
        valueFound = valueFound[0];
      }

      //valueFoundmay have a unit on the end eg W, if trimValue is true, remove the unit
      if (trimValue && valueFound.includes(valueToTrim)) {
        valueFound = valueFound.replace(valueToTrim, "");
      }

      if (formatter) {
        valueFound = formatter(valueFound);
      }

      if (valueFound === null) {
        valueFound = "N/A";
      }
    } catch (e) {
      //error
    }
  }

  return (
    <Card sx={{ display: "flex", flexFlow: "column", height: "100%" }}>
      <CardHeader title={title} />
      <Stack
        spacing={2}
        direction={"row"}
        justifyContent="space-between"
        alignItems="center"
        sx={{
          p: 2,
          pt: 0,
          //full height of the card
          flex: "1 1 auto",
        }}
      >
        <FontAwesomeIcon
          icon={icon}
          size={iconSize}
          color={theme.palette.primary.main}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant={textsize ? textsize : "h2"}
            sx={{
              alignSelf: "center",
              mr: 1,
            }}
          >
            {valueFound}
          </Typography>
          {unit ? <Typography variant="p">{unit}</Typography> : null}
        </Box>
      </Stack>
    </Card>
  );
}
