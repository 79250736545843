import React from "react";
import DeviceTypeBase from "./DeviceTypeBase";
import formatSpeed from "../../Functions/formatSpeed";

export default function Oyster3(props) {
  const { isShare } = props;

  const columns = [
    {
      field: "speedKmph",
      title: "Speed",
      icon: "tachometer-alt",
      iconSize: "6x",
      formatter: (value) => formatSpeed(value, true),
      graphValueFormatter: (value) => formatSpeed(value, false),
      aspect: 10,
      metricWidth: 3,
      graphWidth: 12,
      unit: formatSpeed(0, true, true),
    },
    {
      field: "headingDeg",
      title: "Heading",
      icon: "compass",
      iconSize: "6x",
      formatter: (value) => (value ? value + "°" : "N/A"),
      hideGraph: true,
      metricWidth: 3,
      graphWidth: 3,
    },
    {
      field: "inTrip",
      title: "In Trip",
      icon: "map-marker-alt",
      iconSize: "6x",
      truefalse: true,
      trueV: "Yes",
      falseV: "No",
      formatter: (value) => (value !== undefined && value === 1 ? "Yes" : "No"),
      aspect: 3,
      metricWidth: 3,
      graphWidth: 6,
    },
    {
      field: "manDown",
      title: "Man Down",
      icon: "user-large-slash",
      iconSize: "6x",
      truefalse: true,
      trueV: "Yes",
      falseV: "No",
      formatter: (value) => (value !== undefined && value === 1 ? "Yes" : "No"),
      aspect: 3,
      metricWidth: 3,
      graphWidth: 6,
    },
  ];

  return (
    <DeviceTypeBase
      isShare={isShare}
      columns={columns}
      showBattery={true}
      isTracker={true}
      hideStats={true}
    />
  );
}
